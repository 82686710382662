<style lang="sass" scoped>
.container-login
  // background-color: #f8f9fa
  font-size: 12px
</style>
<template lang="pug">
div.container-login
  .container.async(:class='{done:done}')
    .row.justify-content-center.align-items-center(style='height: 100vh')
      .col-xl-5.col-lg-6.col-md-7
        h3.text-center
          span Welcome to
          strong  Noitaler 👋
        h6.text-muted.text-center 로그인 되어있습니다.
        .mt-4
          p
            strong.mr-2 이메일계정
            br
            | email@notialer.com

          .mb-3
            a(href='#').btn.btn-light.bg-white.border.shadow-sm.py-2.btn-block: strong 나의 비즈니스 목록

          .mb-3
            a(href='#').btn.btn-light.bg-white.border.shadow-sm.py-2.btn-block 계정 로그아웃

</template>

<script>

export default {
  name: 'index',
  components: {

  },
  mounted() {
    // this.$store.dispatch('session')
    this.load()
  },
  data() {
    return {
      done: false,
      form: {},
    }
  },
  methods: {
    async load() {
      try {
        // const r = await this.$http.get('/v1')
        // console.log('ping:', r?.data)

        setTimeout(() => {
          this.done = true
        }, 300);
      } catch (error) {
        console.log({error})
      }
    },
  },
}
</script>
